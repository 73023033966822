import { string, shape, bool, number } from 'prop-types';

const actionPropTypes = {
  label: shape({
    text: string.isRequired,
  }),
  target: string.isRequired,
  tracks: shape({
    analytics_track: shape({
      actio: string.isRequired,
      category: string.isRequired,
      custom_dimensions: shape({}),
    }),
    melidata_track: shape({
      event_data: shape({
        is_new_billboard: bool,
        item_id: string.isRequired,
        position: number.isRequired,
      }),
      path: string.isRequired,
      type: string.isRequired,
    }),
  }),
};

export default actionPropTypes;
