import { string, arrayOf, bool } from 'prop-types';

import actionPropTypes from '../action';

const slideBillboardMobPropType = {
  item: {
    logo_url: string,
    title: string,
    bookmarked: bool,
    id: string,
    item_id: string,
    vertical: string,
    image_url: string,
    image_alt_text: string,
    component_description: arrayOf({
      value: string,
    }),
    action: { ...actionPropTypes },
  },
};

export default slideBillboardMobPropType;
