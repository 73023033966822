import { string, shape, bool, number } from 'prop-types';

import billboardInterventionPropType from './billboard-intervention.desk';

const verticalContentRendererPropType = {
  index: number,
  item: shape({ ...billboardInterventionPropType.items }),
  lowEnd: bool,
  type: string,
};

export default verticalContentRendererPropType;
