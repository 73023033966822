import React from 'react';
import { number, string } from 'prop-types';

import LabelBuilder from '../../../label-builder';

const RenderPrices = ({ amount, id, size, currencyId, className, thousandSeparator, symbol }) => (
  <LabelBuilder
    values={[
      {
        description: amount,
        key: `${id}-price`,
        text: amount,
        type: 'PRICE',
        size,
        className,
        thousandSeparator,
        currencyId,
        symbol,
      },
    ]}
    text={`{${id}-price}`}
  />
);

RenderPrices.propTypes = {
  amount: number.isRequired,
  className: string.isRequired,
  currencyId: string.isRequired,
  id: string.isRequired,
  size: string.isRequired,
  symbol: string,
  thousandSeparator: string.isRequired,
};

export default RenderPrices;
