import { string, arrayOf, shape } from 'prop-types';

import actionPropTypes from '../action';

const billboardInterventionMobPropType = {
  items: arrayOf(
    shape({
      logo_url: string,
      title: string,
      image_url: string,
      image_alt_text: string,
      id: string,
      item_id: string,
      vertical: string,
      bookmarked: string,
      component_description: arrayOf(
        shape({
          font_size: string,
          color: string,
          font_family: string,
          text: string,
          id: string,
          size: string,
          amount: string,
          currency_id: string,
          thousandSeparator: string,
        }),
      ),
      action: { ...actionPropTypes },
    }),
  ),
  type: string,
};

export default billboardInterventionMobPropType;
