import React from 'react';
import { string, bool, number } from 'prop-types';

import { actionPropTypes } from '../../proptypes';
import Link from '../../../../link/link';
import RenderLogo from '../render-logo';
import { trackEvent } from '../../../../../lib/tracking';

const HeaderLogo = ({ action, namespace, title, index, logo_url, lowEnd, type }) => (
  <Link
    href={action.target}
    className={`${namespace}__logo-image-link`}
    onClick={() => {
      trackEvent(action.tracks);
    }}
    title={title}
  >
    <RenderLogo index={index} title={title} logo_url={logo_url} lowEnd={lowEnd} type={type.toLowerCase()} isDesktop />
  </Link>
);

HeaderLogo.propTypes = {
  action: { ...actionPropTypes },
  index: number,
  logo_url: string,
  lowEnd: bool,
  namespace: string,
  title: string,
  type: string,
};

export default HeaderLogo;
