import React from 'react';

import { Image } from 'nordic/image';
import { CarouselSnappedSlide, CarouselSnapped } from '@andes/carousel-snapped';

import VerticalCardContent from './vertical-card-content';
import ItemBookmark from '../../../../bookmark/bookmark';
import { LAZYLOAD_ON, LAZYLOAD_OFF, namespace } from '../../constants';
import { slideBillboardWrapper } from '../../proptypes';
import { trackEvent } from '../../../../../lib/tracking';

const SlideBillboardWrapper = ({ items, lowEnd, type, showUserBookmarks }) => (
  <CarouselSnapped
    arrows={{ size: 'large' }}
    spacing={0}
    type="full"
    pagination={false}
    lazyload
    beforeChange={(index) => {
      const track = items[index]?.tracks;

      trackEvent(track);
    }}
  >
    {items.map((item, index) => {
      const { item_id, image_url, title } = item;

      return (
        <CarouselSnappedSlide className={`${namespace}__card`} key={`slide-bill-board-${item_id}`}>
          <Image
            className={`${namespace}__background`}
            src={image_url}
            preload={index === 0}
            lazyload={index === 0 ? LAZYLOAD_OFF : LAZYLOAD_ON}
            carousel
            alt={title}
          />
          {showUserBookmarks && <ItemBookmark className={`${namespace}__bookmark`} item={item} />}
          <VerticalCardContent item={item} type={type} lowEnd={lowEnd} index={index} />
        </CarouselSnappedSlide>
      );
    })}
  </CarouselSnapped>
);

SlideBillboardWrapper.propTypes = {
  ...slideBillboardWrapper,
};

export default SlideBillboardWrapper;
