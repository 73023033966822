import React from 'react';
import { string, number } from 'prop-types';

import classnames from 'classnames';

import { namespaceMobile as namespace } from '../../constants';
import LabelBuilder from '../../../../label-builder';
import RenderPrices from '../render-prices';

const LabelComponent = ({
  font_size,
  color,
  font_family,
  text,
  id,
  size,
  amount,
  currency_id,
  thousandSeparator,
  symbol,
}) => {
  if (id === 'LABEL_COMPONENT') {
    return (
      <LabelBuilder
        text={text}
        class_name={classnames(
          `${namespace}__content-label--${font_size}`,
          `${namespace}__content-label--${color}`,
          `${namespace}__content-label--${font_family}`,
        )}
      />
    );
  }

  const className = classnames(
    'ui-search-price',
    `${namespace}__content-label--${size}`,
    `${namespace}__content-label--${color}`,
  );

  return (
    <RenderPrices
      amount={amount}
      id={id}
      size={size}
      className={className}
      thousandSeparator={thousandSeparator}
      currencyId={currency_id}
      symbol={symbol}
    />
  );
};

LabelComponent.propTypes = {
  amount: number,
  color: string,
  currency_id: string,
  font_family: string,
  font_size: string,
  id: string,
  size: string,
  symbol: string,
  text: string,
  thousandSeparator: string,
};

export default LabelComponent;
