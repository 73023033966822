import React from 'react';

import classnames from 'classnames';
import { CarouselSnapped } from '@andes/carousel-snapped';

import { trackEvent } from '../../../lib/tracking';
import SlideBillboard from './components/mobile/slide-bill-board';
import { billboardInterventionMobPropType } from './proptypes';
import { namespaceMobile as namespace, VERTICAL_TYPE_MOT, VERTICAL_TYPE_RES } from './constants';

const BillboardIntervention = ({ intervention }) => {
  let { vertical } = intervention[0];

  if (vertical === VERTICAL_TYPE_MOT) {
    vertical = classnames(`${namespace}__${vertical}`);
  }

  return (
    <section className={namespace}>
      <CarouselSnapped
        srLabel=""
        className={vertical === VERTICAL_TYPE_RES ? '' : vertical}
        arrows={{ visibility: 'hover' }}
        type="content"
        pagination={{
          mode: 'light',
          position: 'bottom',
        }}
        spacing={12}
        strictBoundaries={false}
        afterChange={(index) => {
          trackEvent(intervention[index].tracks);
        }}
      >
        {intervention.map((item, i) => (
          <SlideBillboard item={item} index={i} key={`carousel-snapped-${item.item_id}`} />
        ))}
      </CarouselSnapped>
    </section>
  );
};

export default BillboardIntervention;

BillboardIntervention.propTypes = {
  intervention: billboardInterventionMobPropType,
};
