import React from 'react';

import { Button, ButtonText } from '@andes/button';

import BillboardAttributes from '../../../../../components/billboard/attributes/attributes.desktop';
import HeaderLogo from './HeaderLogo';
import HeaderInfoBillboard from './HeaderInfoBillboard';
import RenderPrices from '../render-prices';
import { trackEvent } from '../../../../../lib/tracking';
import { namespace } from '../../constants';
import { verticalContentRendererPropType } from '../../proptypes';

const VerticalCardContent = ({
  item: { title, specifications, strapline, from, price, action, logo_url },
  type,
  lowEnd,
  index,
}) => (
  <div className={`${namespace}__content`}>
    <div className={`${namespace}__header`}>
      {logo_url && (
        <HeaderLogo
          action={action}
          namespace={namespace}
          title={title}
          index={index}
          logo_url={logo_url}
          lowEnd={lowEnd}
          type={type}
        />
      )}
      <HeaderInfoBillboard action={action} namespace={namespace} strapline={strapline} title={title} type={type} />
    </div>
    {from && <span className={`${namespace}__from`}>{from.text}</span>}
    <RenderPrices
      amount={price.amount}
      id={price.id}
      size={price.size}
      currencySymbol={price.currency_symbol}
      className={`${namespace}__price ${namespace}__price--${type?.toLowerCase()}`}
      thousandSeparator={price.thousandSeparator}
      currencyId={price.currency_id}
      symbol={price.symbol}
    />
    {specifications && (
      <BillboardAttributes
        attributes={specifications?.map((attr) => ({ id: attr.id, label: attr.name, data: attr.value_name }))}
      />
    )}
    <Button
      href={action?.target}
      hierarchy="loud"
      size="medium"
      className={`${namespace}__action-button`}
      onClick={() => {
        trackEvent(action?.tracks);
      }}
    >
      <ButtonText>{action?.label?.text}</ButtonText>
    </Button>
  </div>
);

VerticalCardContent.propTypes = {
  ...verticalContentRendererPropType,
};

export default VerticalCardContent;
