import React from 'react';
import { string, bool, number } from 'prop-types';

import { Image } from 'nordic/image';

import { LAZYLOAD_ON, LAZYLOAD_OFF, namespace } from '../constants';

const RenderLogo = ({ logo_url, lowEnd, title, isDesktop = false, type, index = 0, image_alt_text }) => (
  <figure
    className={
      isDesktop
        ? `${namespace}__logo-image-container ${namespace}__logo-image-container--${type}`
        : 'billboard-intervention__logo-image-container'
    }
  >
    <Image
      className={
        isDesktop
          ? `${namespace}__logo-image ${namespace}__logo-image--${type}`
          : 'billboard-intervention__slide--card-top--logo'
      }
      alt={title || image_alt_text}
      src={logo_url}
      preload={index === 0}
      lazyload={lowEnd ? LAZYLOAD_OFF : LAZYLOAD_ON}
    />
  </figure>
);

RenderLogo.propTypes = {
  image_alt_text: string,
  index: number,
  isDesktop: bool,
  logo_url: string.isRequired,
  lowEnd: bool,
  title: string.isRequired,
  type: string,
};

export default RenderLogo;
