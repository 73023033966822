import { string, arrayOf, shape, bool, number } from 'prop-types';

import actionPropTypes from '../action';

const billboardInterventionDeskPropType = {
  items: arrayOf(
    shape({
      title: string.isRequired,
      item_id: string.isRequired,
      vertical: string.isRequired,
      bookmarked: string.isRequired,
      specifications: arrayOf({
        id: string.isRequired,
        name: string.isRequired,
        value_name: string.isRequired,
      }),
      strapline: shape({
        text: string.isRequired,
      }),
      from: shape({
        text: string.isRequired,
      }),
      price: shape({
        amount: number.isRequired,
        color: string.isRequired,
        currency_id: string.isRequired,
        id: string.isRequired,
        size: string.isRequired,
        state: string.isRequired,
      }),
      action: { ...actionPropTypes },
      logo_url: string.isRequired,
      id: string.isRequired,
      image_url: string.isRequired,
      tracks: shape({
        analytics_track: shape({
          actio: string.isRequired,
          category: string.isRequired,
          custom_dimensions: shape({}),
        }),
        melidata_track: shape({
          event_data: shape({
            is_new_billboard: bool,
            item_id: string.isRequired,
            position: number.isRequired,
          }),
          path: string.isRequired,
          type: string.isRequired,
        }),
      }),
    }),
  ),
  type: string.isRequired,
};

export default billboardInterventionDeskPropType;
