import { string, shape, bool, number, arrayOf } from 'prop-types';

import billboardInterventionPropType from './billboard-intervention.desk';

const slideBillboardWrapper = {
  index: number.isRequired,
  items: arrayOf(shape({ ...billboardInterventionPropType.items })),
  lowEnd: bool.isRequired,
  showUserBookmarks: bool.isRequired,
  type: string.isRequired,
};

export default slideBillboardWrapper;
