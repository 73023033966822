import React, { useEffect, useState, useMemo } from 'react';

import classnames from 'classnames';

import SlideBillboard from './components/desktop/slide-bill-board';
import { trackEvent } from '../../../lib/tracking';
import { useStaticProps } from '../../../components/context/static-props';
import { useSearch } from '../../../hooks/context';
import { billboardInterventionDeskPropType } from './proptypes';

const BillboardIntervention = ({ intervention, type }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { lowEnd } = useStaticProps();
  const { bookmark } = useSearch();
  const showUserBookmarks = bookmark?.show_user_bookmarks;

  const classContainerIntervention = classnames('ui-search-carousel--billboard', {
    'ui-search-carousel--billboard--lowend': lowEnd,
    'ui-search-carousel--collapsed': isCollapsed,
  });

  const classToggleCollapseButton = classnames('ui-search-carousel__btn-collapse', {
    'ui-search-carousel__btn-collapsed': isCollapsed,
  });

  useEffect(() => {
    if (intervention.length) {
      trackEvent(intervention[0]?.tracks);
    }
  });

  const sliderMemo = useMemo(
    () => <SlideBillboard items={intervention} lowEnd={lowEnd} type={type} showUserBookmarks={showUserBookmarks} />,
    [intervention, lowEnd, type, showUserBookmarks],
  );

  return (
    <section className={classContainerIntervention}>
      {sliderMemo}
      <button
        className={classToggleCollapseButton}
        type="button"
        aria-label="reduce carrousel"
        tabIndex="0"
        onClick={() => {
          setIsCollapsed(!isCollapsed);
        }}
      />
    </section>
  );
};

BillboardIntervention.propTypes = {
  ...billboardInterventionDeskPropType,
};

export default BillboardIntervention;
