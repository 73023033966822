import React from 'react';
import { string, shape } from 'prop-types';

import { actionPropTypes } from '../../proptypes';
import { trackEvent } from '../../../../../lib/tracking';

const HeaderInfoBillboard = ({ action, namespace, strapline, type, title }) => (
  <div className={`${namespace}__header-info`}>
    {strapline && <span className={`${namespace}__strapline`}>{strapline?.text}</span>}
    <h2 className={`${namespace}__title ${namespace}__title--${type?.toLowerCase()}`}>
      <a
        href={action?.target || '#'}
        className={`${namespace}__link`}
        onClick={() => {
          trackEvent(action?.tracks || {});
        }}
      >
        {title}
      </a>
    </h2>
  </div>
);

HeaderInfoBillboard.propTypes = {
  action: { ...actionPropTypes },
  namespace: string,
  strapline: shape(),
  title: string,
  type: string,
};

export default HeaderInfoBillboard;
